<template>
  <div id="app" class="page" v-bind:class="$store.state.app.pageClass" @click="closeAllDropdowns($event)">

    <div class="navbar">

      <router-link class="navbar__logo" :to="{ name: 'Cabinet' }">
        <img src="~app/../rgs/assets/logo.svg">
      </router-link>

      <nav class="navbar__top-menu top-menu top-menu--user">
        <button class="top-menu__mobile-handler" @click.stop.prevent="toggleMenu($event)" :class="{ 'top-menu__mobile-handler--active' : $store.state.app.showMenu }"><font-awesome-icon icon="bars" /></button>

        <div class="top-menu__container" :class="{ 'top-menu__container--show' : $store.state.app.showMenu }" @click="closeMenu()">
          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Authorization' }" v-if="$store.state.user.authenticated !== true || $store.state.user.role === 'guest'">Войти в Личный кабинет</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" exact :to="{ name: 'Cabinet' }" v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'">Добро пожаловать</router-link>

          <router-link v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'" class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Contacts' }">Контакты</router-link>
        </div>

      </nav>

      <nav class="navbar__top-menu top-menu top-menu--admin" v-if="$store.state.user.authenticated === true && ['manager', 'admin'].includes($store.state.user.role)">
        <button class="top-menu__mobile-handler" @click.stop.prevent="toggleAdminMenu()" :class="{ 'top-menu__mobile-handler--active' : $store.state.app.showAdminMenu }"><font-awesome-icon icon="user-cog" /></button>

        <div class="top-menu__container" :class="{ 'top-menu__container--show' : $store.state.app.showAdminMenu }" @click="closeAdminMenu()">
          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminUsersList', query: { page: 1 } }">Список пользователей</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminOrdersList' }">Список заказов</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminOrdersStatistics' }">Статистика заказов</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminTasks' }">Управление заданиями</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminTransactions' }">Транзакции</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminTransactionsUpload' }">Загрузка транзакций</router-link>
        </div>

      </nav>

      <nav class="navbar__cart" v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'">

        <div class="navbar__balance" v-if="$store.state.user.balance !== false">
          <strong>{{ $store.state.user.balance }}</strong> баллов
        </div>

        <Tasks />

        <router-link v-if="$store.state.cart.items.length > 0" class="navbar__cart-link" active-class="navbar__cart-link--active" :to="{ name: 'Order' }">Оформить заказ</router-link>

      </nav>

      <button v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'" class="button button--red navbar__logout" @click.prevent="logout()">Выйти&nbsp;&rarr;</button>
    </div>

    <div class="logo-container">
      <div class="container">
        <div class="logo-container__wrapper">
          <div class="logo-container__logo-logo">
            <router-link :to="{ name: 'Cabinet' }">
              <img src="~app/../rgs/assets/logo.svg">
            </router-link>
          </div>
          <router-link class="logo-container__logo-eagle" :to="{ name: 'Cabinet' }">
            <img src="~app/../rgs/assets/logo.png" class="logo-container__logo-eagle">
          </router-link>
        </div>
      </div>
    </div>

    <router-view/>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="footer__phone">
              Телефон службы поддержки: <a href="tel:88007009090">8 800 700-90-90</a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="footer__email">
              Электронная почта: <a href="mailto:support@giftery.ru">support@giftery.ru</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import component from '../../rgs/components/App'
export default component
</script>
